import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Form from 'react-bootstrap/Form';
import { Box } from "@mui/system";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";
import { useEffect, useState } from "react";
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined';
import { Col, Row, Container } from "react-bootstrap";
import DatePicker from "@hassanmojab/react-modern-calendar-datepicker";
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BarraSuperior from "../components/BarraSuperior";
import DadoEletricoUsina from "../components/DadoEletricoUsina";
import GraficoEstacao from "../components/GraficoEstacao";
import GraficoGeracaoDiaria from "../components/GraficoGeracaoDiaria";
import GraficoEnergiaMedidor from "../components/GraficoEnergiaMedidor";
import * as usinaService from "../services/usinaService";
import * as inversoresServices from "../services/inversoresService";
import StatusInversores from "../components/StatusInversores";
import { CUSTOM_LOCALE } from "../constants";
import * as estacaoService from "../services/estacoesService";
import * as geracaoDiariaService from "../services/geracaoDiariaService";
import { findAllLastRegister } from "../services/inversoresService";
import { buscarUltimosRegistrosTrackers } from "../services/trackerService";
import { HrDegrade, Painel } from "../styles/GlobalStyles";
import { BoxMet, DadosMet, TextMet, TitleMet } from "../styles/UsinaStyles";
import { downloadFile, formatEstacaoComDni3, formatEstacoesComDni3, formatEstacoesComAlbedo1, getRandomColors } from "../utils/utils";
import nuvem from "../assets/nuvem.png";
import termometro from "../assets/termometro.png";
import vento from "../assets/vento.png";
import umidade from "../assets/umidade.png";
import sol from "../assets/sol.png";
import tracker from "../assets/tracker.png";
import inversor from "../assets/inversor.png";
import grid_icon from "../assets/grid_icon.png";
import { converteParaHorarioLocal } from "../utils/utils";
import { SiGooglesheets, SiJson, SiMicrosoftexcel } from "react-icons/si";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { React, useRef } from 'react'
import { monthMap } from "../utils/utils";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField
} from "@mui/material";
import LoadingSpin from "react-loading-spin";
import { useSnackbar } from "notistack";
import GraficoEnergiaMensal from "../components/GraficoEnergiaMensal";
import GraficoEnergiaMensalPorDia from "../components/GraficoEnergiaMensalPorDia";
import Select from "react-select";
import { inversorChartOptions } from "../utils/utils";
import GenericChart from "../components/GenericChart";
import { TreeSelect } from 'primereact/treeselect';


const DADOS_INV = {
    tensaoA: "tensaoA",
    tensaoB: "tensaoB",
    tensaoC: "tensaoC",
    correnteA: "correnteA",
    correnteB: "correnteB",
    correnteC: "correnteC",
    frequencia: "frequencia",
    potenciaAC: "potenciaAC",
    potenciaDC: "potenciaDC",
    potenciaAtiva: "potenciaAtiva",
    potenciaReativa: "potenciaReativa",
    fatorPotencia: "fatorPotencia",
    eficiencia: "eficiencia",
    energiaTotal: "energiaTotal",
    energiaDiaria: "energiaDiaria",
    energiaMensal: "energiaMensal",
    energiaAnual: "energiaAnual",
    temperatura: "temperatura",
};

const startDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
}

const endDate = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
}
const horaAtual = moment(new Date, "YYYY-MM-DDTHH:mm:ss")

const randomColors = getRandomColors(100)

export default function Usinas() {
    const [nodes, setNodes] = useState(null);
    const [selectedNodeKey, setSelectedNodeKey] = useState({});

    const [showIrradiation, setShowIrradiation] = useState(false)
    const [options, setOptions] = useState(inversorChartOptions);
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const { enqueueSnackbar } = useSnackbar();
    const { usinaId } = useParams();
    const navigate = useNavigate();

    const [usina, setUsina] = useState({});
    const [medidorColumnData, setMedidorColumnData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [geracaoMesPorDia, setGeracaoMesPorDia] = useState([{
        totalDayEnergy: 0,
        date: "2000-01-01"
    }]);
    const [geracaoDoDia, setGeracaoDoDia] = useState([{
        cod: 0,
        dailyEnergy: 0,
        date: "2000-01-01"
    }]);
    const [chartByDay, setCharByDay] = useState(false)
    const [isOpenMonitoramentoDiario, setIsOpenMonitoramentoDiario] =
        useState(false);
    const [isLoadingMonitoramentoDiario, setIsLoadingMonitoramentoDiario] = useState(false)
    const [geracaoDiaria, setGeracaoDiaria] = useState(null);
    const [dadosMeteorologicos, setDadosMeteorologicos] = useState({
        estacaoAlbedos: [{ valor: 0 }],
        estacaoDnis: [{ valor: 0 }]
    });
    const [inversores, setInversores] = useState([]);
    const [trackers, setTrackers] = useState([]);
    const [dadosEstacao, setDadosEstacao] = useState([
        {
            estacaoAlbedos: [{ valor: 0 }],
        },
    ]);
    const [date, setDate] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    });
    const rangeDatePicker = {
        from: startDate,
        to: endDate
    };
    const [daysSelected, setSelectedRange] = useState(rangeDatePicker);
    const [inversorDataRange, setInversorDataRange] = useState(rangeDatePicker);
    const [isOpenExportRelatorioEnergiaTempo, setIsOpenExportRelatorioEnergiaTempo] =
        useState(false);
    const [isOpenExportRelatorioEnergiaMedidor, setIsOpenExportRelatorioEnergiaMedidor] =
        useState(false);
    const [
        isOpenExportRelatorioTemperaturaIrradiancia,
        setIsOpenExportRelatorioTemperaturaIrradiancia,
    ] = useState(false);
    const [isFullScreen, setFullScreen] = useState(Array(4).fill(6))
    const [isLoadingExportRelatorio, setIsOpenExportRelatorio] = useState(false);
    const dispatch = useDispatch();
    const [energiaMedidor, setEnergiaMedidor] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const [chartData, setChartData] = useState();

    async function fullScreenHandler(index) {

        const newCols = [...isFullScreen];
        newCols[index] = newCols[index] == 12 ? 6 : 12;
        setFullScreen(newCols)

        if (!newCols.includes(12)) {
            setDate({
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
                day: new Date().getDate(),
            })
        }

    }

    const handleBydayBarClick = async (clickedDate) => {
        try {
            setIsLoadingMonitoramentoDiario(true)

            const response = await inversoresServices.getFromDay({ usinaId, date: clickedDate })

            setGeracaoDoDia(response)
            setCharByDay(true)
        } catch (error) {
            console.log(error)
        }
        setIsLoadingMonitoramentoDiario(false)
    }

    const handleBarClick = async (clickedDate) => {
        const [month, year] = clickedDate.split('/');
        const monthFormatted = monthMap[month];
        const formattedDate = `${year}-${monthFormatted}-01`;

        try {
            setIsLoadingMonitoramentoDiario(true)
            setIsOpenMonitoramentoDiario(true)

            const response = await usinaService.getEnergiaFromMonth({ usinaId, date: formattedDate })

            setGeracaoMesPorDia(response)
        } catch (error) {
            setIsOpenMonitoramentoDiario(false)
        }
        setIsLoadingMonitoramentoDiario(false)
    };

    function handleSelectChange(e) {
        setSelectedOption(e);
    }

    async function getGeracaoAndEstacaoDataBetweenDatesFullScreen() {
        try {
            setLoading(true);
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };



            intervaloDTO.startDate = moment(
                new Date(
                    isFullScreen.includes(12) ? daysSelected.from.year : date.year,
                    isFullScreen.includes(12) ? daysSelected.from.month - 1 : date.month - 1,
                    isFullScreen.includes(12) ? daysSelected.from.day : date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(
                    isFullScreen.includes(12) ? daysSelected.to.year : date.year,
                    isFullScreen.includes(12) ? daysSelected.to.month - 1 : date.month - 1,
                    isFullScreen.includes(12) ? daysSelected.to.day : date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const diffDays = moment(intervaloDTO.endDate).diff(moment(intervaloDTO.startDate), 'days')

            if (diffDays > 31) {
                intervaloDTO.startDate = moment(intervaloDTO.startDate).add(diffDays - 30, "days").format("YYYY-MM-DDTHH:mm")
            }

            const geracaoDiariaData = isFullScreen[0] == 12 ? await geracaoDiariaService.findBetweenDatesFromUsinaX({
                usinaId,
                intervaloDTO,
            }) : undefined;

            const estacaoData = isFullScreen[1] == 12 ? await estacaoService.findBetweenDatesEstacao({
                usinaId,
                intervaloDTO
            }) : undefined;


            const energiaMedidorData = isFullScreen[2] == 12 || isFullScreen[0] == 12 ? await usinaService.getEnergiaMedidor({ usinaId, intervaloDTO }) : undefined;

            const totalizadorData = isFullScreen[3] == 12 ? await usinaService.getUsinaTotalizador(usinaId) : undefined;


            totalizadorData ? setTotalizadores(totalizadorData) : null;

            estacaoData ? setDadosEstacao(formatEstacoesComAlbedo1(estacaoData)) : null;

            geracaoDiariaData ? setGeracaoDiaria(geracaoDiariaData) : null;

            energiaMedidorData ? setEnergiaMedidor(energiaMedidorData) : null;

            setLoading(false);
        } catch (error) {
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 99 ~ getGeracaoAndEstacaoDataBetweenDatesFullScreen ~ error",
                error
            );
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setLoading(false);
        }
    }

    async function getGeracaoAndEstacaoDataBetweenDates() {
        try {

            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };

            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");


            const [estacaoData, geracaoDiariaData, energiaMedidorData, totalizadorData, inv] =
                await Promise.all([
                    estacaoService.findBetweenDatesEstacao({
                        usinaId,
                        intervaloDTO,
                    }),
                    geracaoDiariaService.findBetweenDatesFromUsinaX({
                        usinaId,
                        intervaloDTO,
                    }),
                    usinaService.getEnergiaMedidor({ usinaId, intervaloDTO }),
                    usinaService.getUsinaTotalizador(usinaId),
                    findAllLastRegister(usinaId)
                ]);
            const estacoesComAlbedo1 = formatEstacoesComAlbedo1(estacaoData);

            setTotalizadores(totalizadorData);
            setDadosEstacao(estacoesComAlbedo1);
            setGeracaoDiaria(geracaoDiariaData);
            setEnergiaMedidor(energiaMedidorData);
            setInversores(inv);


        } catch (error) {
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 99 ~ getGeracaoAndEstacaoDataBetweenDates ~ error",
                error
            );
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setLoading(false);
        }
    }

    const handleExportRelatorioGeracaoDiaria = async ({ reportFormat }) => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            setIsOpenExportRelatorio(true);
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await geracaoDiariaService.exportRelatorioGeracaoDiaria({
                usinaId,
                intervaloDTO,
                reportFormat,
            });

            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-geracao-diaria-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsOpenExportRelatorio(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setIsOpenExportRelatorio(false);
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };

    const handleExportRelatorioEnergiaMedidor = async ({ reportFormat }) => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            setIsOpenExportRelatorio(true);
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await usinaService.exportRelatorioEnergiaMedidor({
                usinaId,
                intervaloDTO,
                reportFormat,
            });
            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-geracao-energia-medidor-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsOpenExportRelatorio(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setIsOpenExportRelatorio(false);
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioGeracaoDiaria ~ error",
                error
            );
        }
    };
    const handleExportRelatorioTemperaturaIrradiancia = async ({ reportFormat }) => {
        try {
            let intervaloDTO = {
                startDate: "",
                endDate: "",
            };
            setIsOpenExportRelatorio(true);
            intervaloDTO.startDate = moment(
                new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
            ).format("YYYY-MM-DDTHH:mm");

            intervaloDTO.endDate = moment(
                new Date(date.year, date.month - 1, date.day, 23, 59, 59)
            ).format("YYYY-MM-DDTHH:mm");

            const responseRelatorio = await estacaoService.exportRelatorioEstacao({
                usinaId,
                intervaloDTO,
                reportTypeDto: {
                    reportType: "TEMPERATURA/IRRADIANCIA",
                    reportFormat,
                },
            });

            const formattedDate = `${date.day}-${date.month}-${date.year}`;
            downloadFile({
                data:
                    reportFormat == "JSON" ? JSON.stringify(responseRelatorio) : responseRelatorio,
                fileName: `relatorio-temperatura-irradiancia-${formattedDate}.${reportFormat.toLowerCase()}`,
                fileType: `text/${reportFormat.toLowerCase()}`,
            });
            setIsOpenExportRelatorio(false);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao gerar o relatório!", { variant: "error" });

            setIsOpenExportRelatorio(false);
            console.log(
                "🚀 ~ file: Usinas.jsx ~ line 127 ~ handleExportRelatorioTemperaturaIrradiancia~ error",
                error
            );
        }
    };

    const renderCustomInputArray = ({ ref }) => (
        <input
            readOnly
            ref={ref} // necessary
            placeholder="Filtre por data"
            value={daysSelected ? formatInputValueArray(daysSelected) : ""}
            style={{
                width: "190px",
                textAlign: "center",
                height: "34px",
                padding: "6px 12px",
                fontSize: "14px",
                lineHeight: "1.42857143",
                color: "#555",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "4px",
            }}
            className="custom-input-class" // a styling class
        />
    );

    const renderCustomInputArrayInversor = ({ ref }) => (
        <input
            readOnly
            ref={ref} // necessary
            placeholder="Filtre por data"
            value={inversorDataRange ? formatInputValueArray(inversorDataRange) : ""}
            style={{
                width: "190px",
                textAlign: "center",
                height: "34px",
                padding: "6px 12px",
                fontSize: "14px",
                lineHeight: "1.42857143",
                color: "#555",
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                borderRadius: "4px",
            }}
            className="custom-input-class" // a styling class
        />
    );

    function formatInputValueArray(datesValue) {

        if (!datesValue.from || !datesValue.to) return "";

        return `${datesValue.from.day > 9 ? datesValue.from.day : `0${datesValue.from.day}`}/${datesValue.from.month > 9 ? datesValue.from.month : `0${datesValue.from.month}`}/${datesValue.from.year} - ${datesValue.to.day > 9 ? datesValue.to.day : `0${datesValue.to.day}`}/${datesValue.to.month > 9 ? datesValue.to.month : `0${datesValue.to.month}`}/${datesValue.to.year}`;
    }

    function responseTimeHandle(responseData) {
        let responseConverted = moment(new Date(converteParaHorarioLocal(responseData)), "YYYY-MM-DDTHH:mm:ss")

        let diff = horaAtual.diff(responseConverted, 'minutes')

        return diff
    }

    const getUsina = async () => {
        try {

            const response = await usinaService.getOneUsina(usinaId);
            setUsina(response);
        } catch (error) {
            enqueueSnackbar("Ocorreu um ao buscar dados da usina!", { variant: "error" });

            setLoading(false);
        }
    };

    function formatDate() {
        let startDate = moment(
            new Date(
                date?.year,
                date?.month - 1,
                date?.day, 0, 0, 0, 0)
        ).format("YYYY-MM-DDTHH:mm");

        let endDate = moment(
            new Date(
                date?.year,
                date?.month - 1,
                date?.day, 23, 59, 59)
        ).format("YYYY-MM-DDTHH:mm");
        return [startDate, endDate]
    }

    async function updatePageData() {

        setLoading(true);

        const [dateFrom, dateTo] = formatDate();

        const [dadosMet, trackersRes, _] = await Promise.all([
            estacaoService.findLastRegister(usinaId),
            buscarUltimosRegistrosTrackers(usinaId),
            getGeracaoAndEstacaoDataBetweenDates(),
        ]);
        const formattedDadosMet = formatEstacaoComDni3(dadosMet);
        setDadosMeteorologicos(formattedDadosMet);
        setTrackers(trackersRes);
        getUsina();


        setLoading(false);
    }

    async function onChangedTreeSelect(nodes) {
        let params = {}
        const keys = Object?.keys(nodes)

        keys.forEach(key => {
            const [equipament, variable] = key.split("-")

            if (!params[equipament]) {
                params[equipament] = []
            }

            if (variable != undefined) {
                params[equipament].push(variable)
            }
        });

        const startDate = moment(
            new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
        ).format("YYYY-MM-DDTHH:mm");

        const endDate = moment(
            new Date(date.year, date.month - 1, date.day, 23, 59, 59)
        ).format("YYYY-MM-DDTHH:mm");

        setSelectedNodeKey(nodes)
        setChartData(await usinaService.getUsinaChart({ usinaId, params, startDate, endDate }))
    }

    useEffect(() => {
        setNodes([
            {
                key: 'inversor',
                label: 'Inversores',
                children: [
                    { key: 'inversor-corrente_a', label: 'Corrente A' },
                    { key: 'inversor-corrente_b', label: 'Corrente B' },
                    { key: 'inversor-corrente_c', label: 'Corrente C' },
                    { key: 'inversor-corrente_max', label: 'Corrente Máxima' },
                    { key: 'inversor-corrente_media', label: 'Corrente Média' },
                    { key: 'inversor-corrente_total', label: 'Corrente Total' },
                    { key: 'inversor-eficiencia', label: 'Eficiência' },
                    { key: 'inversor-e_diaria', label: 'Energia Diária' },
                    { key: 'inversor-e_mensal', label: 'Energia Mensal' },
                    { key: 'inversor-e_total', label: 'Energia Total' },
                    { key: 'inversor-fp', label: 'Fator de Potência (FP)' },
                    { key: 'inversor-frequencia', label: 'Frequência' },
                    { key: 'inversor-pot_ativa', label: 'Potência Ativa' },
                    { key: 'inversor-pot_reativa', label: 'Potência Reativa' },
                    { key: 'inversor-pot_total_dc', label: 'Potência Total DC' },
                    { key: 'inversor-potencia_total_ac', label: 'Potência Total AC' },
                    { key: 'inversor-potencia_total_dc', label: 'Potência Total DC' },
                    { key: 'inversor-temperatura_inv', label: 'Temperatura do Inversor' },
                    { key: 'inversor-tensao_a', label: 'Tensão A' },
                    { key: 'inversor-tensao_b', label: 'Tensão B' },
                    { key: 'inversor-tensao_c', label: 'Tensão C' }
                ]
            },
            {
                key: 'measurer',
                label: 'Medidor',
                children: [
                    { key: 'measurer-geracao', label: 'Geração' },
                    { key: 'measurer-medicao_distribuida', label: 'Medição Distribuída' },
                    { key: 'measurer-potencia', label: 'Potência Ativa' }
                ]
            },
            {
                key: 'tracker',
                label: 'Tracker',
                children: [
                    { key: 'tracker-angulo_ideal', label: 'Ângulo Ideal' },
                    { key: 'tracker-angulo_real', label: 'Ângulo Real' }
                ]
            },
            {
                key: 'station',
                label: 'Estação Meteorológica',
                children: [
                    { key: 'station-temperatura_ar', label: 'Temperatura do Ar' },
                    { key: 'station-umidade', label: 'Umidade' },
                    { key: 'station-vento', label: 'Velocidade do Vento' }
                ]
            },
            {
                key: 'rele',
                label: 'Relé',
                children: [
                    { key: 'rele-corrente_a', label: 'Corrente A' },
                    { key: 'rele-corrente_b', label: 'Corrente B' },
                    { key: 'rele-corrente_c', label: 'Corrente C' },
                    { key: 'rele-fator_potencia_a', label: 'Fator de Potência A' },
                    { key: 'rele-fator_potencia_b', label: 'Fator de Potência B' },
                    { key: 'rele-fator_potencia_c', label: 'Fator de Potência C' },
                    { key: 'rele-frequencia_barra', label: 'Frequência da Barra' },
                    { key: 'rele-frequencia_linha', label: 'Frequência da Linha' },
                    { key: 'rele-temperatura', label: 'Temperatura' },
                    { key: 'rele-tensao_a', label: 'Tensão A' },
                    { key: 'rele-tensao_b', label: 'Tensão B' },
                    { key: 'rele-tensao_c', label: 'Tensão C' }
                ]
            }
        ]);

    }, []);

    useEffect(() => {

        if (isFullScreen.includes(12)) {
            daysSelected.from && daysSelected.to ? getGeracaoAndEstacaoDataBetweenDatesFullScreen() : null;
            return null
        }
        setChartData(null)
        onChangedTreeSelect(selectedNodeKey)


        let counter = 0;
        dispatch({ type: "SET_COUNTER", value: counter });

        const intervalId = setInterval(() => {
            counter++;
            dispatch({ type: "SET_COUNTER", value: counter });
            if (counter === 160) {
                // atualização a cada x segundos
                updatePageData();
                counter = 0;
                dispatch({ type: "SET_COUNTER", value: counter });
            }
        }, 1000);

        return () => clearInterval(intervalId);

    }, [usinaId, isFullScreen, daysSelected, date]);

    useEffect(() => {
        if (!usinaId) navigate("/");

        updatePageData(); // carregamento inicial

        setSelectedRange(rangeDatePicker)

        if (isFullScreen.includes(12)) {
            setFullScreen(Array(4).fill(6))
        }

    }, [usinaId, date]);

    function calcularMedia(dado) {
        let sum = 0;
        switch (dado) {
            case DADOS_INV.tensaoA:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.tensao_a;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.tensaoB:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.tensao_b;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.tensaoC:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.tensao_c;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.correnteA:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.corrente_a;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.correnteB:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.corrente_b;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.correnteC:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.corrente_c;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.frequencia:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.frequencia;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.potenciaAC:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.potencia_total_ac;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.potenciaDC:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.potencia_total_dc;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.fatorPotencia:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.fp;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.energiaDiaria:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.e_diaria;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.temperatura:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.temperatura_inv;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.energiaTotal:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.e_total;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.potenciaAtiva:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.pot_ativa;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.potenciaReativa:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.pot_reativa;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.energiaMensal:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.e_mensal;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.energiaAnual:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.e_anual;
                }, 0);
                return sum / inversores.length;
            case DADOS_INV.eficiencia:
                sum = inversores.reduce((prev, current) => {
                    return prev + current.eficiencia;
                }, 0);
                return sum / inversores.length;
            default:
                return sum;
        }
    }

    function formatInputValue(date) {
        if (!date) return "";

        let format = date.day > 9 ? `${date.day}/` : `0${date.day}/`;
        format += date.month > 9 ? `${date.month}/` : `0${date.month}/`;
        format += date.year;

        return format;
    }

    function download(fileUrl, fileName) {
        let a = document.createElement("a");
        a.href = fileUrl;
        a.setAttribute("download", fileName);
        a.click();
    }

    let geracaoDiariaJsx = (
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                height: "75vh",
            }}
        >
            <CircularProgress />
        </Box>
    );

    if (!loading) {
        // render regular HTML input element
        const renderCustomInput = ({ ref }) => (
            <input
                readOnly
                ref={ref} // necessary
                placeholder="Filtre por data"
                value={date ? formatInputValue(date) : ""}
                style={{
                    width: "96px",
                    height: "34px",
                    padding: "6px 12px",
                    fontSize: "14px",
                    lineHeight: "1.42857143",
                    color: "#555",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
                className="custom-input-class" // a styling class
            />
        );

        const renderCustomInputEndDate = ({ ref }) => (
            <input
                readOnly
                ref={ref} // necessary
                placeholder="Filtre por data"
                value={endDate ? formatInputValue(endDate) : ""}
                style={{
                    width: "96px",
                    height: "34px",
                    padding: "6px 12px",
                    fontSize: "14px",
                    lineHeight: "1.42857143",
                    color: "#555",
                    backgroundColor: "#fff",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                }}
                className="custom-input-class" // a styling class
            />
        );

        geracaoDiariaJsx = (
            <>
                <BarraSuperior totalizadores={totalizadores} medidor={energiaMedidor} />
                <Painel>
                    {isFullScreen.includes(12) ? isFullScreen[3] == 12 ? null : (
                        <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
                            <Col lg={12}>
                                <DatePicker
                                    value={daysSelected}
                                    onChange={setSelectedRange}
                                    renderInput={renderCustomInputArray}
                                    locale={CUSTOM_LOCALE}
                                ></DatePicker>
                            </Col>
                        </Row>
                    ) : (
                        <Row style={{ textAlign: "center", marginBottom: "1rem" }}>
                            <DatePicker
                                value={date}
                                onChange={setDate}
                                renderInput={renderCustomInput}
                                locale={CUSTOM_LOCALE}
                            ></DatePicker>
                        </Row>
                    )}



                    <Row>
                        {isFullScreen[0] != 12 && isFullScreen.includes(12) ? null : (
                            <Col md={12} lg={isFullScreen[0]} style={{ textAlign: "center" }}>
                                <b onClick={() => fullScreenHandler(0)} style={{ fontSize: "20px", cursor: "pointer" }}>Potência Ativa (kW) x Tempo</b>{" "}
                                {isFullScreen.includes(12) ? null : (<Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => setIsOpenExportRelatorioEnergiaTempo(true)}
                                >
                                    Exportar relatório
                                </Button>
                                )}
                                <div style={{ width: "100%" }}>
                                    {isFullScreen[0] != 12 ?
                                        (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(0)}
                                            >
                                                <FullscreenIcon className="" style={{}} />
                                                Expandir Gráfico
                                            </Button>
                                        ) : (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(0)}
                                            >
                                                <FullscreenExitIcon className="" style={{}} />
                                                Reduzir Gráfico
                                            </Button>
                                        )
                                    }
                                    <GraficoGeracaoDiaria energiaMedidor={energiaMedidor} geracaoDiaria={geracaoDiaria} fullScreen={isFullScreen[0]} date={date} startDate={daysSelected.from} endDate={daysSelected.to} />
                                </div>
                            </Col>
                        )}
                        {isFullScreen[1] != 12 && isFullScreen.includes(12) ? null : (
                            <Col md={12} lg={isFullScreen[1]} style={{ textAlign: "center", cursor: "pointer", transition: "width 0.1s ease-out" }}>
                                <b onClick={() => fullScreenHandler(1)} style={{ fontSize: "20px" }}>
                                    Temperatura (ºC) / Irradiância (W/m²) x Tempo
                                </b>

                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => setIsOpenExportRelatorioTemperaturaIrradiancia(true)}
                                >
                                    Exportar relatório
                                </Button>
                                <div style={{ width: "100%" }}>
                                    {isFullScreen[1] != 12 ?
                                        (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(1)}
                                            >
                                                <FullscreenIcon style={{}} />
                                                Expandir Gráfico
                                            </Button>
                                        ) : (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(1)}
                                            >
                                                <FullscreenExitIcon style={{}} />
                                                Reduzir Gráfico
                                            </Button>
                                        )
                                    }
                                    <GraficoEstacao estacao={dadosEstacao} fullScreen={isFullScreen[1]} date={date} startDate={daysSelected.from} endDate={daysSelected.to} />
                                </div>
                            </Col>
                        )}
                        {isFullScreen[2] != 12 && isFullScreen.includes(12) ? null : (
                            <Col
                                md={12}
                                lg={isFullScreen[2]} style={{ textAlign: "center", cursor: "pointer", transition: "width 0.1s ease-out", marginTop: 32, marginBottom: 4 }}>
                                <b onClick={() => fullScreenHandler(2)} style={{ fontSize: "20px" }}>Energia medidor (kW/h) x Tempo</b>{" "}
                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => setIsOpenExportRelatorioEnergiaMedidor(true)}
                                >
                                    Exportar relatório
                                </Button>
                                <div style={{ width: "100%" }}>
                                    {isFullScreen[2] != 12 ?
                                        (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(2)}
                                            >
                                                <FullscreenIcon style={{}} />
                                                Expandir Gráfico
                                            </Button>
                                        ) : (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(2)}
                                            >
                                                <FullscreenExitIcon style={{}} />
                                                Reduzir Gráfico
                                            </Button>
                                        )
                                    }
                                    <GraficoEnergiaMedidor energia={energiaMedidor} fullScreen={isFullScreen[2]} date={date} startDate={daysSelected.from} endDate={daysSelected.to} />
                                </div>
                            </Col>
                        )}
                        {isFullScreen[3] != 12 && isFullScreen.includes(12) ? null : (
                            <Col
                                md={12}
                                lg={isFullScreen[3]} style={{ textAlign: "center", cursor: "pointer", transition: "width 0.1s ease-out", marginTop: 32, marginBottom: 4 }}
                            >
                                <b onClick={() => fullScreenHandler(3)} style={{ fontSize: "20px" }}>Energia mensal (MWh)</b>{" "}
                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 14px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    disabled
                                    onClick={() => setIsOpenExportRelatorioEnergiaTempo(true)}
                                >
                                    Exportar relatório
                                </Button>
                                <div style={{ width: "100%" }}>
                                    {isFullScreen[3] != 12 ?
                                        (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(3)}
                                            >
                                                <FullscreenIcon style={{}} />
                                                Expandir Gráfico
                                            </Button>
                                        ) : (
                                            <Button variant="contained" style={{ color: "white", margin: "1%", backgroundColor: "#0a5068", textTransform: "none", marginLeft: "20px" }}
                                                onClick={() => fullScreenHandler(3)}
                                            >
                                                <FullscreenExitIcon style={{}} />
                                                Reduzir Gráfico
                                            </Button>
                                        )
                                    }
                                    <GraficoEnergiaMensal
                                        energiaMensal={totalizadores.energia_mensal
                                            .map((i) => i.valor)
                                            .reverse()}
                                        date={date}
                                        onBarClick={handleBarClick}
                                    />
                                </div>
                            </Col>
                        )}

                    </Row>


                    {isFullScreen.includes(12) ? null : (<HrDegrade />)}

                    {isFullScreen.includes(12) ? null : (
                        <Row style={{ paddingInline: "12rem" }}>
                            <Col sm={12}>
                                <DadosMet>
                                    <div style={{ textAlign: "center", marginBottom: "15px" }}>
                                        <img
                                            src={nuvem}
                                            alt=""
                                            style={{
                                                marginRight: "5px",
                                                width: "40px",
                                            }}
                                        />
                                        <b style={{ fontSize: "20px" }}>Estação metereológica</b>
                                        <Button
                                            variant="contained"
                                            style={{
                                                padding: "0 14px",
                                                color: "white",
                                                backgroundColor: "#0a5068",
                                                textTransform: "none",
                                                marginLeft: "20px",
                                            }}
                                            onClick={() =>
                                                navigate(`/estacao_metereologica/${usinaId}`)
                                            }
                                        >
                                            Detalhes
                                        </Button>
                                    </div>
                                    <BoxMet>
                                        <div sm={3} style={{ textAlign: "center" }}>
                                            <img src={termometro} alt="" style={{ width: "30px", height: "30px" }} />
                                            <TitleMet>Temperatura Módulo</TitleMet>
                                            <TextMet>
                                                {parseFloat(
                                                    dadosMeteorologicos?.estacaoTemperaturaMods?.find(
                                                        (temperatura) => temperatura.cod == 1
                                                    )?.valor
                                                ).toFixed(2)}{" "}
                                                °C
                                            </TextMet>
                                        </div>
                                        <div sm={3} style={{ textAlign: "center" }}>
                                            <img src={termometro} alt="" style={{ width: "30px", height: "30px" }} />
                                            <TitleMet>Temperatura</TitleMet>
                                            <TextMet>
                                                {parseFloat(
                                                    dadosMeteorologicos?.temperatura_ar
                                                ).toFixed(2)}{" "}
                                                °C
                                            </TextMet>
                                        </div>
                                        <div sm={3} style={{ textAlign: "center" }}>
                                            <img src={sol} alt="" style={{ width: "30px", height: "30px" }} />
                                            <TitleMet>Irradiação Horizontal</TitleMet>
                                            <TextMet>

                                                {parseFloat(dadosMeteorologicos?.dni3).toFixed(2)}{" "}
                                                w/m²
                                            </TextMet>
                                        </div>
                                        <div sm={3} style={{ textAlign: "center" }}>
                                            <img src={sol} alt="" style={{ width: "30px", height: "30px" }} />
                                            <TitleMet>Irradiação Inclinada</TitleMet>
                                            <TextMet>
                                                {parseFloat(dadosMeteorologicos?.estacaoDnis?.find((item) => item.cod == 1)?.valor).toFixed(2)}{" "}
                                                w/m²
                                            </TextMet>
                                        </div>
                                        <div sm={3} style={{ textAlign: "center" }}>
                                            <img src={sol} alt="" style={{ width: "30px", height: "30px" }} />
                                            <TitleMet>Irradiação Refletida</TitleMet>
                                            <TextMet>
                                                {parseFloat(dadosMeteorologicos?.estacaoDnis?.find((item) => item.cod == 2)?.valor).toFixed(2)}{" "}
                                                w/m²
                                            </TextMet>
                                        </div>
                                        <div sm={3} style={{ textAlign: "center" }}>
                                            <img src={vento} alt="" style={{ width: "30px", height: "30px" }} />
                                            <TitleMet>Vento</TitleMet>
                                            <TextMet>
                                                {parseFloat(dadosMeteorologicos?.vento).toFixed(2)} m/s
                                            </TextMet>
                                        </div>
                                        <div sm={3} style={{ textAlign: "center" }}>
                                            <img src={umidade} alt="" style={{ width: "30px", height: "30px" }} />
                                            <TitleMet>Umidade</TitleMet>
                                            <TextMet>
                                                {parseFloat(dadosMeteorologicos?.umidade).toFixed(2)} %
                                            </TextMet>
                                        </div>
                                    </BoxMet>
                                </DadosMet>
                            </Col>
                            {usina.planta && (
                                <>
                                    <Col sm={2}>
                                        {" "}
                                        <DadosMet>
                                            <b
                                                style={{
                                                    fontSize: "20px",
                                                    alignSelf: "center",
                                                    marginBottom: 8,
                                                }}
                                            >
                                                Topologia
                                            </b>
                                            <img
                                                style={{
                                                    width: 200,
                                                    height: 150,
                                                    alignSelf: "center",
                                                    cursor: "pointer",
                                                    // marginLeft: isVisualizar ? 8 : 36,
                                                }}
                                                className="playerProfilePic_home_tile"
                                                src={usina.planta}
                                                onClick={() => download(usina.planta, "topologia.png")}
                                            ></img>
                                        </DadosMet>
                                    </Col>
                                </>
                            )}
                        </Row>
                    )}

                    {isFullScreen.includes(12) ? null : (<HrDegrade />)}

                    {isFullScreen.includes(12) ? null : (
                        <Row style={{ marginTop: "20px" }}>
                            <div style={{ marginBottom: "40px" }}>
                                <img
                                    src={tracker}
                                    alt=""
                                    style={{
                                        marginRight: "5px",
                                        marginLeft: "10px",
                                        marginBottom: "4px",
                                    }}
                                />
                                <b style={{ fontSize: "20px" }}>Trackers</b>
                                <Button
                                    variant="contained"
                                    style={{
                                        padding: "0 10px",
                                        color: "white",
                                        backgroundColor: "#0a5068",
                                        textTransform: "none",
                                        marginLeft: "20px",
                                    }}
                                    onClick={() => navigate(`/trackers/${usinaId}`)}
                                >
                                    Todos os Trackers
                                </Button>
                            </div>
                        </Row>
                    )}

                    {isFullScreen.includes(12) ? null : (
                        <Box
                            sx={{
                                textAlign: "center",
                                overflowY: "overlay",
                                height: "230px",
                                display: "flex",
                                flexWrap: "wrap",
                            }}
                        >
                            {isFullScreen.includes(12) ? null : trackers.map((tracker, index) => (
                                <Box
                                    key={index}
                                    sm={1}
                                    style={{
                                        marginBottom: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "125px",
                                    }}
                                >
                                    <div
                                        style={{
                                            borderBottom: "1px solid #A5AAB6",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100px",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <div>
                                                <img src={grid_icon} style={{ width: "18px" }} />
                                            </div>
                                            <Box display={"flex"} alignItems={"center"}>
                                                {responseTimeHandle(tracker?.data) >= 20 ? (
                                                    <>
                                                        <span
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#ff9233",
                                                            }}
                                                        >
                                                            Offline
                                                        </span>
                                                        <CloudOffOutlinedIcon style={{ color: "#ff9233" }} />
                                                    </>
                                                ) : tracker?.status === 1 ? (
                                                    <>
                                                        <span
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#049B5F",
                                                            }}
                                                        >
                                                            OK
                                                        </span>
                                                        <CheckCircleOutlineIcon
                                                            style={{ color: "#049B5F" }}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <span
                                                            style={{
                                                                fontWeight: 700,
                                                                fontSize: "18px",
                                                                letterSpacing: "0.02rem",
                                                                color: "#D40707",
                                                            }}
                                                        >
                                                            Falha
                                                        </span>
                                                        <CancelOutlinedIcon
                                                            style={{ color: "#D40707" }}
                                                        />
                                                    </>
                                                )}
                                            </Box>
                                        </div>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                color: responseTimeHandle(tracker?.data) >= 20 ? "#4E596B" : tracker?.status === 1 ? "#4E596B" : "#D40707",

                                            }}
                                        >
                                            {`tracker ${tracker?.cod}`.toString().padStart(2, "0")}{" "}
                                        </span>
                                    </div>
                                    <span
                                        style={{
                                            fontWeight: 700,
                                            fontSize: "12px",
                                            letterSpacing: "0.02rem",
                                            color: responseTimeHandle(tracker?.data) >= 20 ? "#A5AAB6" : tracker?.status === 1 ? "#A5AAB6" : "#D40707",
                                        }}
                                    >
                                        {Math.abs(parseFloat(tracker?.angulo_real.toFixed(2)))}º
                                        {tracker?.angulo_real < 0 ? (
                                            <span> Leste</span>
                                        ) : tracker?.angulo_real > 0 ? (
                                            <span> Oeste</span>
                                        ) : (
                                            ""
                                        )}
                                    </span>
                                </Box>
                            ))}
                        </Box>

                    )}

                    {isFullScreen.includes(12) ? null : (<HrDegrade />)}


                    {isFullScreen.includes(12) ? null : (
                        <Box>
                            <Row style={{ marginTop: "20px" }}>
                                <Row
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <Col sm={7}>
                                        <Row
                                            style={{
                                                padding: "0 10px",
                                            }}
                                        >
                                            <div style={{ marginBottom: "38px" }}>
                                                <img
                                                    src={inversor}
                                                    alt=""
                                                    style={{ marginRight: "5px", marginBottom: "4px" }}
                                                />
                                                <b style={{ fontSize: "20px" }}>Inversores</b>
                                                <Button
                                                    variant="contained"
                                                    style={{
                                                        padding: "0 10px",
                                                        color: "white",
                                                        backgroundColor: "#0a5068",
                                                        textTransform: "none",
                                                        marginLeft: "20px",
                                                    }}
                                                    onClick={() => navigate(`/inversores/${usinaId}`)}
                                                >
                                                    Todos os Inversores
                                                </Button>
                                            </div>
                                        </Row>
                                        <Row style={{ padding: "0 10px" }}>
                                            <Col
                                                style={{
                                                    padding: "0",
                                                    borderRight: "1px solid #C4C4C4",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: "bolder",
                                                        borderBottom: "1px solid #888888",
                                                        padding: "0 10px",
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    Média Saida (CA)
                                                </p>

                                                <DadoEletricoUsina
                                                    dado="Tensão A"
                                                    unidade="(Volts)"
                                                    valor={calcularMedia(DADOS_INV.tensaoA).toLocaleString(
                                                        "pt-BR"
                                                    )}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Tensão B"
                                                    unidade="(Volts)"
                                                    valor={calcularMedia(DADOS_INV.tensaoB).toLocaleString(
                                                        "pt-BR"
                                                    )}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Tensão C"
                                                    unidade="(Volts)"
                                                    valor={calcularMedia(DADOS_INV.tensaoC).toLocaleString(
                                                        "pt-BR"
                                                    )}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Corrente A"
                                                    unidade="(Amperes)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.correnteA
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Corrente B"
                                                    unidade="(Amperes)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.correnteB
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Corrente C"
                                                    unidade="(Amperes)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.correnteC
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Frequência"
                                                    unidade="(Hertz)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.frequencia
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Potência Ativa"
                                                    unidade="(kW)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.potenciaAtiva
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Potência Reativa"
                                                    unidade="(kVAr)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.potenciaReativa
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Fator de Potência"
                                                    valor={calcularMedia(
                                                        DADOS_INV.fatorPotencia
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Eficiência"
                                                    unidade="(%)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.eficiencia
                                                    ).toLocaleString("pt-BR")}
                                                />
                                            </Col>
                                            <Col
                                                style={{
                                                    padding: "0",
                                                    borderRight: "1px solid #C4C4C4",
                                                }}
                                            >
                                                <p
                                                    style={{
                                                        fontWeight: "bolder",
                                                        borderBottom: "1px solid #888888",
                                                        padding: "0 10px",
                                                        fontSize: "18px",
                                                    }}
                                                >
                                                    Média Entrada (CC)
                                                </p>
                                                <DadoEletricoUsina
                                                    dado="Corrente A"
                                                    unidade="(Amperes)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.correnteA
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Corrente B"
                                                    unidade="(Amperes)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.correnteB
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Corrente C"
                                                    unidade="(Amperes)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.correnteC
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Potência"
                                                    unidade="(kW)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.potenciaDC
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Energia Total"
                                                    unidade="(kWh)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.energiaTotal
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Energia Diária"
                                                    unidade="(kWh)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.energiaDiaria
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Energia Mensal"
                                                    unidade="(kWh)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.energiaMensal
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Energia Anual"
                                                    unidade="(kWh)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.energiaAnual
                                                    ).toLocaleString("pt-BR")}
                                                />
                                                <DadoEletricoUsina
                                                    dado="Temperatura"
                                                    unidade="(ºC)"
                                                    valor={calcularMedia(
                                                        DADOS_INV.temperatura
                                                    ).toLocaleString("pt-BR")}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col
                                        style={{
                                            textAlign: "center",
                                            overflowY: "overlay",
                                            height: "490px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            marginTop: "40px",
                                        }}
                                        sm={5}
                                    >
                                        <StatusInversores usinaId={usinaId} />
                                    </Col>
                                </Row>
                            </Row>
                            <HrDegrade />
                            <Row
                                style={{
                                    padding: "10px",
                                }}
                            >
                                <Col md={2} style={{ marginBottom: "38px" }}>

                                </Col>
                                <Col md={3}>
                                    <Form>
                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Comparar irradiância"
                                            checked={showIrradiation}
                                            onChange={(e) => {
                                                setShowIrradiation(!showIrradiation)
                                            }}
                                        />
                                    </Form>
                                </Col>
                                <Col md={2}>
                                    <DatePicker
                                        value={date}
                                        onChange={setDate}
                                        renderInput={renderCustomInput}
                                        locale={CUSTOM_LOCALE}
                                    ></DatePicker>
                                </Col>
                                <Col md={3}>
                                    <TreeSelect className="d-flex" value={selectedNodeKey} selectionMode="multiple" options={nodes} onChange={(e) => onChangedTreeSelect(e.value)} placeholder="Select Item"></TreeSelect>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center" style={{ marginTop: "20px" }}>
                                <Col md={11}>
                                    <GenericChart chartData={chartData} selectLabel={selectedOption} date={date} randomColors={randomColors} irradiation={dadosEstacao} showIrradiation={showIrradiation} />
                                </Col>
                            </Row>
                        </Box>
                    )}
                </Painel >

                <Dialog
                    maxWidth="md"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenMonitoramentoDiario}
                    onClose={() => { setIsOpenMonitoramentoDiario(false), setCharByDay(false) }}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Monitoramento Diário da Geração.
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingMonitoramentoDiario ? (
                            <>
                                <Box
                                    sx={{
                                        margin: "20%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="50px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                {chartByDay ? (
                                    <GraficoEnergiaMensalPorDia geracao={geracaoDoDia} chartByDay />
                                ) : (
                                    <GraficoEnergiaMensalPorDia geracao={geracaoMesPorDia} onBarClick={handleBydayBarClick} />
                                )}

                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Container>
                            <Row>
                                <Col >
                                    {chartByDay ? (
                                        <Button
                                            style={{ color: "#0a5068" }}
                                            onClick={() => setCharByDay(!chartByDay)}
                                        >
                                            Voltar
                                        </Button>
                                    ) : (
                                        <></>
                                    )}
                                </Col>
                                <Col className="text-end">
                                    <Button
                                        style={{ color: "#0a5068" }}
                                        onClick={() => { setIsOpenMonitoramentoDiario(false), setCharByDay(false) }}
                                    >
                                        Fechar
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </DialogActions>
                </Dialog>

                <Dialog
                    maxWidth="sm"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenExportRelatorioEnergiaTempo}
                    onClose={() => setIsOpenExportRelatorioEnergiaTempo(false)}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Exportar relatório de <b>Potência Ativa (kW) x Tempo</b>
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingExportRelatorio ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Divider
                                    variant="middle"
                                    sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                />
                                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioGeracaoDiaria({
                                                reportFormat: "CSV",
                                            })
                                        }
                                    >
                                        <b>CSV</b>{" "}
                                        <SiGooglesheets style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioGeracaoDiaria({
                                                reportFormat: "XLSX",
                                            })
                                        }
                                    >
                                        <b>XLSX</b>{" "}
                                        <SiMicrosoftexcel
                                            style={{ color: "white", marginLeft: 6 }}
                                        />
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioGeracaoDiaria({
                                                reportFormat: "JSON",
                                            })
                                        }
                                    >
                                        <b>JSON</b>{" "}
                                        <SiJson style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                </Box>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => setIsOpenExportRelatorioEnergiaTempo(false)}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="sm"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenExportRelatorioEnergiaMedidor}
                    onClose={() => setIsOpenExportRelatorioEnergiaMedidor(false)}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Exportar relatório de <b>Energia medidor (kW/h) x Tempo</b>
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingExportRelatorio ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Divider
                                    variant="middle"
                                    sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                />
                                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioEnergiaMedidor({
                                                reportFormat: "CSV",
                                            })
                                        }
                                    >
                                        <b>CSV</b>{" "}
                                        <SiGooglesheets style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioEnergiaMedidor({
                                                reportFormat: "XLSX",
                                            })
                                        }
                                    >
                                        <b>XLSX</b>{" "}
                                        <SiMicrosoftexcel
                                            style={{ color: "white", marginLeft: 6 }}
                                        />
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioEnergiaMedidor({
                                                reportFormat: "JSON",
                                            })
                                        }
                                    >
                                        <b>JSON</b>{" "}
                                        <SiJson style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                </Box>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => setIsOpenExportRelatorioEnergiaMedidor(false)}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth="md"
                    fullWidth
                    disableScrollLock={true}
                    open={isOpenExportRelatorioTemperaturaIrradiancia}
                    onClose={() => setIsOpenExportRelatorioTemperaturaIrradiancia(false)}
                >
                    <DialogTitle style={{ color: "#0a5068" }}>
                        Exportar relatório de <b>Temperatura (ºC) / Irradiância (W/m²) x Tempo</b>
                    </DialogTitle>
                    <DialogContent>
                        {isLoadingExportRelatorio ? (
                            <>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <LoadingSpin size="16px" width="2px" primaryColor={"#2e64a8"} />
                                </Box>
                            </>
                        ) : (
                            <>
                                <Divider
                                    variant="middle"
                                    sx={{ mb: 4, color: "#0a5068", padding: "0.5px" }}
                                />
                                <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTemperaturaIrradiancia({
                                                reportFormat: "CSV",
                                            })
                                        }
                                    >
                                        <b>CSV</b>{" "}
                                        <SiGooglesheets style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTemperaturaIrradiancia({
                                                reportFormat: "XLSX",
                                            })
                                        }
                                    >
                                        <b>XLSX</b>{" "}
                                        <SiMicrosoftexcel
                                            style={{ color: "white", marginLeft: 6 }}
                                        />
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            color: "white",
                                            backgroundColor: "#0a5068",
                                            textTransform: "none",
                                        }}
                                        onClick={() =>
                                            handleExportRelatorioTemperaturaIrradiancia({
                                                reportFormat: "JSON",
                                            })
                                        }
                                    >
                                        <b>JSON</b>{" "}
                                        <SiJson style={{ color: "white", marginLeft: 6 }} />
                                    </Button>
                                </Box>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions style={{ color: "#0a5068" }}>
                        <Button
                            style={{ color: "#0a5068" }}
                            onClick={() => setIsOpenExportRelatorioTemperaturaIrradiancia(false)}
                        >
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return geracaoDiariaJsx;
}
