import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import CircleSharpIcon from "@mui/icons-material/CircleSharp";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SolarPowerIcon from "@mui/icons-material/SolarPower";
import Tooltip from "@mui/material/Tooltip";
import HomeIcon from "@mui/icons-material/Home";
import FactoryIcon from "@mui/icons-material/Factory";
import { Link, useLocation, useParams } from "react-router-dom";
import Inversores from "./Inversores";
import EstacaoMetereologica from "./EstacaoMetereologica";
import { ContainerGeral, SideBar } from "../styles/GlobalStyles";
import Usinas from "./Usinas";
import Configuracoes from "./Configuracoes";
import Home from "./Home";
import Header from "../components/Header";
import Usuarios from "./Usuarios";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SettingsIcon from "@mui/icons-material/Settings";
import ControleAlarmes from "./ControleAlarmes";
import Reles from "./Reles";
import TrackerMap from "./TrackerMap"
import VisualizarAlarmes from "./VisualizarAlarmes";
import ControleUsinas from "./ControleUsinas";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import Trackers from "./Trackers";
import { getAllUsinas } from "../services/usinaService";
import logo from "../assets/logo.png";
import logo2 from "../assets/Logo_nome2.png";
import { useAuth } from "../hooks/Auth";
import Relatorios from "./Relatorios";
const drawerWidth = 240;

const URL_NAME_PAGES = {
    inversores: "inversores",
    usinas: "usinas",
    configuracoes: "configuracoes",
    home: "home",
    page5: "page5",
    usuarios: "usuarios",
    controleAlarmes: "controle_alarmes",
    visualizarAlarmes: "alarmes",
    controleUsinas: "controle_usinas",
    estacaoMetereologica: "estacao_metereologica",
    trackers: "trackers",
    relatorios: "relatorios",
    reles: "reles",
    trackers_map: "trackers_map"
};

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 0px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(9)} + 0px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function MiniDrawer() {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [openConfiguracoes, setOpenConfiguracoes] = React.useState(false);
    const [openUsinas, setOpenUsinas] = React.useState(false);
    const [openUsina, setOpenUsina] = React.useState(false);
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const [selectedSubItemIndex, setSelectedSubItemIndex] = React.useState(0);
    const [selectedUsina, setSelectedUsina] = React.useState(0);
    const [usinas, setUsinas] = React.useState([]);
    const location = useLocation();
    const { getUserPerfil } = useAuth();
    const userPerfil = getUserPerfil();
    const [openLayout, setOpenLayout] = React.useState(false);

    const handleLayoutClick = () => {
        setOpenLayout(!openLayout);
    };

    React.useEffect(() => {
        getUsinas();
    }, []);

    const getUsinas = async () => {
        try {
            const response = await getAllUsinas();

            setUsinas(response);
        } catch (error) {
            console.log("🚀 ~ file: Global.jsx ~ line 127 ~ getUsinas ~ error", error);
        }
    };

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleClickConfiguracoes = () => {
        setOpenConfiguracoes(!openConfiguracoes);
        handleDrawerOpen();
    };

    const handleClickUsinas = () => {
        setOpenUsinas(!openUsinas);
        handleDrawerOpen();
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
        setOpenConfiguracoes(false);
        setOpenUsinas(false);
        setOpenUsina(false);
        setSelectedUsina(0);
    };

    const { page, usinaId } = useParams();

    return (
        <ContainerGeral>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <Drawer variant="permanent" open={open}>
                    <Box component={SideBar}>
                        {!open && (
                            <>
                                <div
                                    style={{
                                        textAlign: "center",
                                        marginTop: "26px",
                                    }}
                                >
                                    <img
                                        src={logo}
                                        style={{
                                            width: "52px",
                                        }}
                                    />
                                </div>
                                <hr
                                    style={{
                                        marginBottom: 0,
                                        background:
                                            "linear-gradient(270deg, rgba(78, 89, 107, 0) 0%, #4E596B 48.53%, rgba(78, 89, 107, 0) 98.08%)",
                                    }}
                                />
                                <Toolbar style={{ justifyContent: "center" }}>
                                    <IconButton
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        edge="start"
                                        sx={{
                                            margin: "0px 0px 0px 1px",
                                            color: "#f0f2f3",
                                            display: open ? "none" : "block",
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Toolbar>
                            </>
                        )}
                        {open && (
                            <DrawerHeader
                                style={{
                                    backgroundImage: `url(${logo2})`,
                                    backgroundSize: "93px",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",

                                }}
                            >
                                <IconButton
                                    onClick={handleDrawerClose}
                                    sx={{
                                        color: "#f0f2f3",
                                    }}
                                >
                                    {theme.direction === "rtl" ? (
                                        <ChevronRightIcon />
                                    ) : (
                                        <ChevronLeftIcon />
                                    )}
                                </IconButton>
                            </DrawerHeader>
                        )}
                        {/* //home */}
                        <List >
                            <Link
                                to={`/${URL_NAME_PAGES.home}`}
                                style={{
                                    color: "white",
                                    textDecoration: "none",
                                }}
                            >
                                <ListItem
                                    sx={{
                                        "&.Mui-selected": {
                                            backgroundColor: "#062635",
                                            color: "white",
                                            fontWeight: 600,
                                        },
                                        width: "100%",
                                        pl: 3,
                                    }}
                                    key={"home"}
                                    button
                                    selected={selectedIndex === 1}
                                    onClick={(event) => handleListItemClick(event, 1)}
                                >
                                    <ListItemIcon style={{ color: "white" }}>
                                        <Tooltip title="Home" arrow>
                                            <HomeIcon />
                                        </Tooltip>
                                    </ListItemIcon>
                                    <ListItemText primary={"Home"} />
                                </ListItem>
                            </Link>
                            {/* //usinas */}
                            <ListItem
                                selected={selectedIndex === 2}
                                button
                                onClick={(event) => {
                                    handleClickUsinas();
                                    handleListItemClick(event, 2);
                                }}
                                sx={{ pl: 3 }}
                                key={"usinas"}
                            >
                                <ListItemIcon style={{ color: "white" }}>
                                    <Tooltip title="Usinas" arrow>
                                        <SolarPowerIcon />
                                    </Tooltip>
                                </ListItemIcon>
                                <ListItemText primary={"Usinas"} style={{ color: "white" }} />
                                {openUsinas ? (
                                    <ExpandLess style={{ color: "white" }} />
                                ) : (
                                    <ExpandMore style={{ color: "white" }} />
                                )}
                            </ListItem>
                            <Collapse in={openUsinas} timeout="auto" unmountOnExit>
                                {usinas.map((usina, index) => (
                                    <>
                                        <List>
                                            <Link
                                                to={
                                                    selectedUsina == usina.id
                                                        ? location.pathname
                                                        : `/${URL_NAME_PAGES.usinas}/${usina.id}`
                                                }
                                                key={"usina" + index}
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <ListItem
                                                    sx={{
                                                        "&.Mui-selected": {
                                                            backgroundColor: "#062635",
                                                            color: "white",
                                                            fontWeight: 600,
                                                        },
                                                        width: "100%",
                                                    }}
                                                    index={`usina_${usina.id}`}
                                                    selected={
                                                        selectedSubItemIndex === "2" + usina.id
                                                    }
                                                    button
                                                    onClick={(event) => {
                                                        if (selectedUsina == usina.id) {
                                                            setSelectedUsina(0);
                                                        } else {
                                                            setSelectedUsina(usina.id);
                                                        }

                                                        setSelectedSubItemIndex("2" + usina.id);
                                                    }}
                                                    key={"usinas"}
                                                >
                                                    <ListItemText
                                                        style={{ color: "white" }}
                                                        primary={usina.nome}
                                                    />
                                                </ListItem>
                                            </Link>
                                            <Collapse
                                                in={usina.id == selectedUsina}
                                                timeout="auto"
                                                unmountOnExit
                                            >
                                                <List>
                                                    <Link
                                                        to={`/${URL_NAME_PAGES.inversores}/${usina.id}`}
                                                        key={"inversores"}
                                                        style={{
                                                            color: "white",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        <ListItem button sx={{ pl: 4 }}>
                                                            {location.pathname.includes(
                                                                "/inversores"
                                                            ) && (
                                                                    <CircleSharpIcon
                                                                        style={{
                                                                            marginLeft: -16,
                                                                            marginRight: 4,
                                                                            fontSize: 12,
                                                                        }}
                                                                    />
                                                                )}
                                                            <ListItemText primary="Inversores" />
                                                        </ListItem>
                                                    </Link>
                                                    <Link
                                                        to={`/${URL_NAME_PAGES.estacaoMetereologica}/${usina.id}`}
                                                        key={"estacao_metereologica"}
                                                        style={{
                                                            color: "white",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        <ListItem button sx={{ pl: 4 }}>
                                                            {location.pathname.includes(
                                                                "/estacao_metereologica"
                                                            ) && (
                                                                    <CircleSharpIcon
                                                                        style={{
                                                                            marginLeft: -16,
                                                                            marginRight: 4,
                                                                            fontSize: 12,
                                                                        }}
                                                                    />
                                                                )}
                                                            <ListItemText primary="Estação Metereológica" />
                                                        </ListItem>
                                                    </Link>
                                                    <Link
                                                        to={`/${URL_NAME_PAGES.trackers}/${usina.id}`}
                                                        key={"trackers"}
                                                        style={{
                                                            color: "white",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        <ListItem button onClick={handleLayoutClick} sx={{ pl: 4 }}>
                                                            {location.pathname.includes("/trackers/") && (
                                                                <CircleSharpIcon
                                                                    style={{
                                                                        marginLeft: -16,
                                                                        marginRight: 4,
                                                                        fontSize: 12,
                                                                    }}
                                                                />
                                                            )}
                                                            <ListItemText primary="Trackers" />
                                                        </ListItem>
                                                    </Link>
                                                    {usina.id == 39 && (
                                                        <>

                                                            <Collapse in={openLayout} timeout="auto" unmountOnExit>
                                                                <Link
                                                                    to={`/${URL_NAME_PAGES.trackers_map}/${usina.id}`}
                                                                    key={"trackers_map"}
                                                                    style={{
                                                                        color: "white",
                                                                        textDecoration: "none",
                                                                    }}
                                                                >
                                                                    <ListItem button sx={{ pl: 8 }}>
                                                                        {location.pathname.includes("/trackers_map/") && (
                                                                            <CircleSharpIcon
                                                                                style={{
                                                                                    marginLeft: -16,
                                                                                    marginRight: 4,
                                                                                    fontSize: 12,
                                                                                }}
                                                                            />
                                                                        )}
                                                                        <ListItemText primary="Layout" />
                                                                    </ListItem>
                                                                </Link>
                                                            </Collapse>
                                                        </>
                                                    )}
                                                    <Link
                                                        to={`/${URL_NAME_PAGES.reles}/${usina.id}`}
                                                        key={"reles"}
                                                        style={{
                                                            color: "white",
                                                            textDecoration: "none",
                                                        }}
                                                    >
                                                        <ListItem button sx={{ pl: 4 }}>
                                                            {location.pathname.includes(
                                                                "/reles"
                                                            ) && (
                                                                    <CircleSharpIcon
                                                                        style={{
                                                                            marginLeft: -16,
                                                                            marginRight: 4,
                                                                            fontSize: 12,
                                                                        }}
                                                                    />
                                                                )}
                                                            <ListItemText primary="Reles" />
                                                        </ListItem>
                                                    </Link>
                                                </List>
                                            </Collapse>
                                        </List>
                                    </>
                                ))}
                            </Collapse>

                            {userPerfil === "RESPONSAVEL_USINA" ? (
                                <Link
                                    to={`/${URL_NAME_PAGES.visualizarAlarmes}`}
                                    style={{
                                        color: "white",
                                        textDecoration: "none",
                                    }}
                                >
                                    <ListItem
                                        sx={{
                                            "&.Mui-selected": {
                                                backgroundColor: "#062635",
                                                color: "white",
                                                fontWeight: 600,
                                            },
                                            width: "100%",
                                            pl: 3,
                                        }}
                                        key={"home"}
                                        button
                                        selected={selectedIndex === 4}
                                        onClick={(event) => handleListItemClick(event, 4)}
                                    >
                                        <ListItemIcon style={{ color: "white" }}>
                                            <Tooltip title="Alarmes" arrow>
                                                <NotificationsActiveIcon />
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText primary={"Alarmes"} />
                                    </ListItem>
                                </Link>
                            ) : (
                                <>
                                    <ListItem
                                        button
                                        onClick={(event) => {
                                            handleListItemClick(event, 3);
                                            handleClickConfiguracoes();
                                        }}
                                        selected={selectedIndex === 3}
                                        key={"configuracoes"}
                                        sx={{ pl: 3 }}
                                    >
                                        <ListItemIcon style={{ color: "white" }}>
                                            <Tooltip title="Configurações" arrow>
                                                <SettingsIcon />
                                            </Tooltip>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={"Configurações"}
                                            style={{ color: "white" }}
                                        />
                                        {openConfiguracoes ? (
                                            <ExpandLess style={{ color: "white" }} />
                                        ) : (
                                            <ExpandMore style={{ color: "white" }} />
                                        )}
                                    </ListItem>
                                    <Collapse in={openConfiguracoes} timeout="auto" unmountOnExit>
                                        <List>
                                            <Link
                                                to={`/${URL_NAME_PAGES.controleAlarmes}`}
                                                key={"alarmes"}
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <ListItem
                                                    selected={selectedSubItemIndex === "3" + 1}
                                                    onClick={() => {
                                                        setSelectedSubItemIndex("3" + 1);
                                                    }}
                                                    button
                                                    sx={{
                                                        "&.Mui-selected": {
                                                            backgroundColor: "#062635",
                                                            color: "white",
                                                            fontWeight: 600,
                                                        },
                                                        width: "100%",
                                                        pl: 4,
                                                    }}
                                                >
                                                    <ListItemIcon style={{ color: "white" }}>
                                                        <NotificationsActiveIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Alarmes" />
                                                </ListItem>
                                            </Link>
                                            <Link
                                                to={`/${URL_NAME_PAGES.controleUsinas}`}
                                                key={"usinas"}
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <ListItem
                                                    sx={{
                                                        "&.Mui-selected": {
                                                            backgroundColor: "#062635",
                                                            color: "white",
                                                            fontWeight: 600,
                                                        },
                                                        width: "100%",
                                                        pl: 4,
                                                    }}
                                                    button
                                                    selected={selectedSubItemIndex === "3" + 2}
                                                    onClick={() => {
                                                        setSelectedSubItemIndex("3" + 2);
                                                    }}
                                                >
                                                    <ListItemIcon style={{ color: "white" }}>
                                                        <FactoryIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Usinas" />
                                                </ListItem>
                                            </Link>
                                            <Link
                                                to={`/${URL_NAME_PAGES.usuarios}`}
                                                key={"usuarios"}
                                                style={{
                                                    color: "white",
                                                    textDecoration: "none",
                                                }}
                                            >
                                                <ListItem
                                                    button
                                                    selected={selectedSubItemIndex === "3" + 3}
                                                    onClick={() => {
                                                        setSelectedSubItemIndex("3" + 3);
                                                    }}
                                                    sx={{
                                                        "&.Mui-selected": {
                                                            backgroundColor: "#062635",
                                                            color: "white",
                                                            fontWeight: 600,
                                                        },
                                                        width: "100%",
                                                        pl: 4,
                                                    }}
                                                >
                                                    <ListItemIcon style={{ color: "white" }}>
                                                        <PersonIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary="Usuários" />
                                                </ListItem>
                                            </Link>
                                        </List>
                                    </Collapse>
                                    <Link
                                        to={`/${URL_NAME_PAGES.relatorios}`}
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                        }}
                                    >
                                        <ListItem
                                            sx={{
                                                "&.Mui-selected": {
                                                    backgroundColor: "#062635",
                                                    color: "white",
                                                    fontWeight: 600,
                                                },
                                                width: "100%",
                                                pl: 3,
                                            }}
                                            key={"relatorios"}
                                            button
                                            selected={selectedIndex === 5}
                                            onClick={(event) => handleListItemClick(event, 5)}
                                        >
                                            <ListItemIcon style={{ color: "white" }}>
                                                <Tooltip title="Relatórios" arrow>
                                                    <FindInPageIcon />
                                                </Tooltip>
                                            </ListItemIcon>
                                            <ListItemText primary={"Relatórios"} />
                                        </ListItem>
                                    </Link>
                                </>
                            )}
                        </List>
                    </Box>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        p: 3,
                        // color: "#f0f2f3",
                        padding: "0px 0px 0px 0px",
                    }}
                >
                    <div>
                        <Header />
                        {page.includes(URL_NAME_PAGES.inversores) && <Inversores />}
                        {page === URL_NAME_PAGES.usinas && <Usinas />}
                        {page === URL_NAME_PAGES.configuracoes && <Configuracoes />}
                        {page === URL_NAME_PAGES.home && <Home />}
                        {page === URL_NAME_PAGES.usuarios && <Usuarios />}
                        {page === URL_NAME_PAGES.controleAlarmes && <ControleAlarmes />}
                        {page === URL_NAME_PAGES.visualizarAlarmes && <VisualizarAlarmes />}
                        {page === URL_NAME_PAGES.controleUsinas && <ControleUsinas />}
                        {page === URL_NAME_PAGES.estacaoMetereologica && <EstacaoMetereologica />}
                        {page === URL_NAME_PAGES.trackers && <Trackers />}
                        {page === URL_NAME_PAGES.relatorios && <Relatorios />}
                        {page.includes(URL_NAME_PAGES.reles) && <Reles />}
                        {page.includes(URL_NAME_PAGES.trackers_map) && <TrackerMap />}
                    </div>
                </Box>
            </Box >
        </ContainerGeral >
    );
}
