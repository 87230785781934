import { Paper, Box, CircularProgress } from "@mui/material";
import React, { useRef, useEffect, useState } from 'react';
import BarraSuperior from "../components/BarraSuperior";
import moment from "moment";
import { useParams } from "react-router-dom";
import * as usinaService from "../services/usinaService";
import tracker from "../assets/tracker.png";
import * as alarmeService from "../services/alarmeService";
import { useDispatch } from "react-redux";
import { Col, Row, Container } from "react-bootstrap"
import SquareIcon from '@mui/icons-material/Square';

const filters = {
    nome: "",
    usina: "39",
    tipo_equipamento: "tracker",
    status: "ativo",
    data_inicio: null,
    data_fim: null,
};

export default function TrackerMap() {
    const [energiaMedidor, setEnergiaMedidor] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const date = {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
    };
    const { usinaId } = useParams();
    const iframeRef = useRef(null);
    const [html, setHtmlContent] = useState()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    async function getData() {
        const response = await fetch('https://mvtoth-files.s3.amazonaws.com/api_mv.txt');
        const data = await response.text();
        setHtmlContent(data);

        const alarmes = await alarmeService.getAllAlarmsWithoutPagination({
            filters: filters,
            currentPage: 0,
            itemsPerPage: 100
        });

        return alarmes.alarmes
    }

    useEffect(async () => {
        setLoading(true);

        const alarmes = await getData()

        setLoading(false);

        processarResposta(alarmes);

        let intervaloDTO = {
            startDate: "",
            endDate: "",
        };

        intervaloDTO.startDate = moment(
            new Date(date.year, date.month - 1, date.day, 0, 0, 0, 0)
        ).format("YYYY-MM-DDTHH:mm");

        intervaloDTO.endDate = moment(
            new Date(date.year, date.month - 1, date.day, 23, 59, 59)
        ).format("YYYY-MM-DDTHH:mm");

        const [energiaMedidorData, totalizadorData] =
            await Promise.all([
                usinaService.getEnergiaMedidor({ usinaId, intervaloDTO }),
                usinaService.getUsinaTotalizador(usinaId),
            ])

        setEnergiaMedidor(energiaMedidorData);
        setTotalizadores(totalizadorData);



        //return () => clearInterval(intervalId);
    }, []);

    // Função para processar a resposta e adicionar classes
    async function processarResposta(alarmes) {
        alarmes.forEach(alarme => {
            if (alarme?.tracker?.cod && alarme?.alarmeTipo?.descricao != "Sobrecorrente na saída 24V") {
                const elemento = document.getElementById(`trk-${String(alarme.tracker.cod).padStart(2, "0")}`);
                if (elemento) {
                    elemento.classList.add('vermelho');
                    elemento.setAttribute('title', `Tracker ${alarme.tracker.cod}`);

                    // Adiciona o evento de mouseover para exibir o título
                    elemento.addEventListener('mouseover', () => {
                        const tooltip = document.createElement('div');
                        tooltip.textContent = `Tracker ${alarme.tracker.cod} - ${alarme?.alarmeTipo?.descricao}`;
                        tooltip.style.position = 'absolute';
                        tooltip.style.backgroundColor = 'white';
                        tooltip.style.border = '1px solid black';
                        tooltip.style.padding = '5px';
                        tooltip.style.zIndex = '1000';

                        const rect = elemento.getBoundingClientRect();
                        tooltip.style.left = `${rect.left}px`;
                        tooltip.style.top = `${rect.bottom + 5}px`;

                        document.body.appendChild(tooltip);

                        elemento.addEventListener('mouseout', () => {
                            document.body.removeChild(tooltip);
                        }, { once: true });
                    });
                }
            }
        });
    }


    let trackerMap = (
        <>
            {loading ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "75vh",
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : (<>
                <BarraSuperior totalizadores={totalizadores} medidor={energiaMedidor} />
                <Paper
                    style={{ minHeight: " 90vh", padding: "38px" }} elevation={3}
                >

                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            mb: 8,
                        }}
                    >
                        <img src={tracker} alt="" style={{ marginRight: "5px" }} />

                        <b style={{ fontSize: "20px", marginLeft: 8, marginTop: -3 }}>
                            Trackers
                        </b>
                    </Box>
                    <Container>
                        <Row>
                            <Col md={5}>
                                <Paper style={{ backgroundColor: "#fafdf5" }}>
                                    <SquareIcon sx={{ color: "green" }} /> Tracker OK <br />
                                    <SquareIcon sx={{ color: "red" }} /> Tracker com ao menos 1 alarme ativo
                                </Paper>
                            </Col>
                        </Row>
                    </Container>

                    <div
                        className="text-center"
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </Paper>
            </>)
            }
        </>
    )

    return trackerMap

}